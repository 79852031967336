<template>
    <div 
        v-for="(player, id) in settedPlayers" 
        class="bg-[#8FC408] fixed right-[10px] px-[20px] flex items-center rounded-[10px] h-[50px]"
        :style="['bottom: ' + (10 + 60 * id) + 'px']"
        :key="settedPlayers"
    >
        Дані по гравцю {{ player.student_name }} внесені
    </div>
    <div 
        v-for="(player, id) in updatedPlayers" 
        class="bg-[#339989] fixed right-[10px] px-[20px] flex items-center rounded-[10px] h-[50px]"
        :style="['bottom: ' + (10 + 60 * id) + 'px']"
        :key="updatedPlayers"    
    >
        Дані по гравцю {{ player.student_name }} оновлені
    </div>
    <div v-if="!showMediaFlag" class="mx-auto mt-[-55px] z-0 flex justify-between">
        <div class="w-[40%]">
            <div><b class="text-[#BD9135]">Тип елемента:</b> <span class="text-[#fff]">{{element.name}}</span></div>
            <div><b class="text-[#BD9135]">Назва вправи:</b> <span class="text-[#fff]">{{parseElementType(element.type)}}</span></div>
            <div><b class="text-[#BD9135]">Вікова група:</b> <span class="text-[#fff]">{{parseAgeGroup(element.training_program_years)}}</span></div>
            <div class="mt-[50px] text-[#BD9135] flex items-center">
                <b class="mr-[3px]">Час виконання (хв.):</b>
                <input :value="element.time" @change="e => $emit('changeTime', e.target.value)" class="bg-[#1b1b1b] outline-none text-white text-[14px] w-[150px] py-[5px] px-[5px] rounded-[6px]" placeholder="Введіть час">
            </div>
            <div class="mt-[50px] text-[#BD9135]"><b>Опис елементу:</b></div>
            <div class="text-[#fff]">
                {{element.info}}
            </div>
            <div v-if="element.additional_images.length > 0">
                <div class="font-bold text-[#BD9135]">Ілюстрації</div>
                <div class="flex mt-[15px]">
                    <!-- <img v-for="picture in element.additional_images" class="mr-[10px] cursor-pointer" src="@/assets/icons/picture_icon.svg" /> -->

                    <carousel :per-page="1">
                        <slide v-for="picture in element.additional_images" :key="picture">
                            <img class="mr-[10px] cursor-pointer w-[250px]" :src="apiURL + 'static/' + picture" />
                        </slide>
                    </carousel>
                </div>
            </div>
            <div v-if="element.schemas.length > 0" class="mt-[35px]">
                <div class="font-bold text-[#BD9135]">Схеми</div>
                <div class="flex mt-[15px]">
                    <!-- <img v-for="schema in element.schemas" class="mr-[10px] cursor-pointer" src="@/assets/icons/picture_icon.svg" /> -->

                    <carousel :per-page="1">
                        <slide v-for="schema in element.schemas" :key="schema">
                            <img class="mr-[10px] cursor-pointer w-[250px]" :src="apiURL + 'static/' + schema" />
                        </slide>
                    </carousel>
                </div>
            </div>
            <div v-if="element.additional_videos.length > 0" class="mt-[35px]">
                <div class="font-bold text-[#BD9135]">Відео</div>
                <div class="flex mt-[15px]">
                    <img v-for="video in element.additional_videos" @click="() => {videoToShow = video; showMediaFlag = true}" class="mr-[10px] cursor-pointer" src="@/assets/icons/video_icon.svg" />
                </div>
            </div>
            <div v-if="typeScore == 'time'" class="relative flex justify-center mt-[30px] w-[276px] mx-auto">
                <div class="flex justify-between absolute w-[256px] bottom-[-10px]">
                    <div class="text-[#339989] text-center text-[11px] relative left-[31px]">чудово</div>
                    <div class="text-[#BD9135] text-center text-[11px] relative left-[6px]">добре</div>
                    <div class="text-[#DA4167] text-center text-[11px] relative right-[25px]">недостатньо</div>
                </div>
                <div class="absolute">
                    <svg width="276" height="31" viewBox="0 0 276 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="131.5" y1="13" x2="131.5" y2="24" stroke="#BD9135"/>
                        <line x1="204.5" y1="14" x2="204.5" y2="23" stroke="#BD9135"/>
                        <line x1="62.5" y1="14" x2="62.5" y2="23" stroke="#BD9135"/>
        
                        <line x1="63" y1="18.5" x2="204" y2="18.5" stroke="#BD9135"/>
                        <line x1="205" y1="18.5" x2="267" y2="18.5" stroke="#DA4167"/>
                        <line x1="-4.37114e-08" y1="18.5" x2="62" y2="18.5" stroke="#339989"/>
                    </svg>
                </div>
                <div class="flex justify-center absolute w-[256px] bottom-[-45px]">
                    <div class="text-[#BD9135] text-center text-[11px] relative left-[-6px]">{{ middleValue }}</div>
                </div>
                <div class="flex justify-center absolute w-[256px] bottom-[-45px]">
                    <div class="text-[#DA4167] text-center text-[11px] relative left-[66px]">{{ maxValue }}</div>
                </div>
                <div class="flex justify-center absolute w-[256px] bottom-[-45px]">
                    <div class="text-[#339989] text-center text-[11px] relative left-[-76px]">{{ minValue }}</div>
                </div>
            </div>
            <div v-if="typeScore == 'length'" class="relative flex justify-center mt-[60px] w-[276px] mx-auto">
                <div class="flex justify-between absolute w-[256px] bottom-[-10px]">
                    <div class="text-[#DA4167] text-center text-[11px] relative left-[20px]">недостатньо</div>
                    <div class="text-[#BD9135] text-center text-[11px] relative right-[20px]">добре</div>
                    <div class="text-[#339989] text-center text-[11px] relative right-[45px]">чудово</div>
                </div>
                <div class="absolute top-[14px]">
                    <svg width="276" height="31" viewBox="0 0 276 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="131.5" y1="-2.18557e-08" x2="131.5" y2="11" stroke="#BD9135"/>
                        <line x1="204.5" y1="1" x2="204.5" y2="10" stroke="#BD9135"/>
                        <line x1="62.5" y1="1" x2="62.5" y2="10" stroke="#BD9135"/>

                        <line x1="63" y1="5.5" x2="204" y2="5.50001" stroke="#BD9135"/>
                        <line x1="205" y1="5.5" x2="267" y2="5.49999" stroke="#339989"/>
                        <line x1="-4.37114e-08" y1="5.5" x2="62" y2="5.49999" stroke="#DA4167"/>
                    </svg>
                </div>
                <div class="flex justify-center absolute w-[256px] bottom-[-45px]">
                    <div class="text-[#BD9135] text-center text-[11px] relative left-[-6px]">{{ middleValue }}</div>
                </div>
                <div class="flex justify-center absolute w-[256px] bottom-[-45px]">
                    <div class="text-[#339989] text-center text-[11px] relative left-[66px]">{{ maxValue }}</div>
                </div>
                <div class="flex justify-center absolute w-[256px] bottom-[-45px]">
                    <div class="text-[#DA4167] text-center text-[11px] relative left-[-76px]">{{ minValue }}</div>
                </div>
            </div>

            <div v-if="typeScore == 'count'" class="relative flex justify-center mt-[30px] w-[276px] mx-auto">
                <div class="flex justify-between absolute w-[256px] bottom-[-10px]">
                    <div class="text-[#DA4167] text-center text-[11px] relative left-[20px]">недостатньо</div>
                    <div class="text-[#BD9135] text-center text-[11px] relative right-[20px]">добре</div>
                    <div class="text-[#339989] text-center text-[11px] relative right-[45px]">чудово</div>
                </div>
                <div class="absolute top-[14px]">
                    <svg width="276" height="31" viewBox="0 0 276 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="131.5" y1="-2.18557e-08" x2="131.5" y2="11" stroke="#BD9135"/>
                        <line x1="204.5" y1="1" x2="204.5" y2="10" stroke="#BD9135"/>
                        <line x1="62.5" y1="1" x2="62.5" y2="10" stroke="#BD9135"/>

                        <line x1="63" y1="5.5" x2="204" y2="5.50001" stroke="#BD9135"/>
                        <line x1="205" y1="5.5" x2="267" y2="5.49999" stroke="#339989"/>
                        <line x1="-4.37114e-08" y1="5.5" x2="62" y2="5.49999" stroke="#DA4167"/>
                    </svg>
                </div>
                <div class="flex justify-center absolute w-[256px] bottom-[-45px]">
                    <div class="text-[#BD9135] text-center text-[11px] relative left-[-6px]">{{ middleValue }}</div>
                </div>
                <div class="flex justify-center absolute w-[256px] bottom-[-45px]">
                    <div class="text-[#339989] text-center text-[11px] relative left-[66px]">{{ maxValue }}</div>
                </div>
                <div class="flex justify-center absolute w-[256px] bottom-[-45px]">
                    <div class="text-[#DA4167] text-center text-[11px] relative left-[-76px]">{{ minValue }}</div>
                </div>
            </div>
        </div>
        <div v-if="element.type == 'standard'" class="w-[50%] h-[500px] overflow-y-auto px-[10px]">
            <TableWrapper v-if="!loaded">
                <TableComponent :fullWidth="true">
                    <TableHeader>
                    <TableHeaderRow>
                        <TableHeaderCell :value="'гравець'" class="truncate"/>
                        <TableHeaderCell :value="''" />
                        <TableHeaderCell :value="''"  />
                        <TableHeaderCell :value="parseStandardType(element.score_board_type)"/>
                        <TableHeaderCell :value="''"  />
                    </TableHeaderRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow  
                            v-for="(player, id) in sortStudentsByResult(allStudents)"
                        >
                            <TableCell :asChild="true">
                                <div class="flex">
                                    <div class="w-[50px] px-3 py-2 text-end font-medium text-sm">
                                        <div class="w-10 h-10 rounded-full overflow-hidden flex items-end bg-[#BD9135] bg-opacity-20 border-[#BD9135] border-[1px]">
                                            <img v-if="player.photo" :src="apiURL + 'static/' + player.photo">
                                            <div v-else class="w-full h-full  text-[#BD9135] flex justify-center items-center">
                                                {{ player.name[0] + player.surname[0] }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="px-3 py-2 text-start font-medium text-sm">
                                        <div class="truncate">
                                            {{ player.name + ' ' + player.surname }}
                                        </div>
                                        <div class="truncate text-[10px] text-gray-400">{{ '#' + player.player_number + ' | ' + player.amplua + ' | ' + player.age + 'p. | ' + player.nationality }}</div>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div class="w-[22px] text-center font-semibold rounded-full">
                                    {{ player.ratingPlace }}
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div class="mx-[20px]">
                                    <div v-if="player.mark == 'good' && player.tested" class="border-2 border-[#BD9135] p-[2px] w-[20px] rounded-full">
                                        <div class="bg-[#BD9135] w-3 h-3 rounded-full"></div>
                                    </div>
                                    <div v-if="player.mark == 'excellent' && player.tested" class="border-2 border-[#339989] p-[2px] w-[20px] rounded-full">
                                        <div class="bg-[#339989] w-3 h-3 rounded-full"></div>
                                    </div>
                                    <div v-if="player.mark == 'bad' && player.tested" class="border-2 border-[#DA4167] p-[2px] w-[20px] rounded-full">
                                        <div class="bg-[#DA4167] w-3 h-3 rounded-full"></div>
                                    </div>
                                    <div v-if="!player.tested" class="border-2 border-[#474849] p-[2px] w-[20px] rounded-full">
                                        <div class="bg-[#474849] w-3 h-3 rounded-full"></div>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div>
                                    <div v-if="element.score_board_type == 'time'" class="flex justify-center mt-[6px]">
                                        <div class="w-[28px]">
                                            <div>
                                                <input :value="player.hours" @change="e => player.hours = e.target.value" type="text" class="w-[28px] h-[28px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[16px] outline-none text-bold" placeholder="00">
                                            </div>
                                        </div>
                                        <div class="relative w-[14px] text-center text-[38px] text-bold text-[#BD9135]">
                                            <div class="leading-[35px]">
                                                :
                                            </div>
                                        </div>
                                        <div class="w-[28px]">
                                            <div>
                                                <input :value="player.minutes" @change="e => player.minutes = e.target.value" type="text" class="w-[28px] h-[28px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[16px] outline-none text-bold" placeholder="00">
                                            </div>
                                        </div>
                                        <div class="relative w-[14px] text-center text-[28px] text-bold text-[#BD9135]">
                                            <div class="leading-[35px]">
                                                :
                                            </div>
                                        </div>
                                        <div class="w-[28px]">
                                            <div>
                                                <input :value="player.seconds" @change="e => player.seconds = e.target.value" type="text" class="w-[28px] h-[28px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[16px] outline-none text-bold" placeholder="00">
                                            </div>
                                        </div>
                                        <div class="relative w-[14px] text-center text-[28px] text-bold text-[#BD9135]">
                                            <div class="leading-[35px]">
                                                ,
                                            </div>
                                        </div>
                                        <div class="w-[28px]">
                                            <div>
                                                <input :value="player.miliseconds" @change="e => player.miliseconds = e.target.value" type="text" class="w-[28px] h-[28px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[16px] outline-none text-bold" placeholder="00">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="element.score_board_type == 'length'" class="flex justify-center">
                                        <div class="w-[28px]">
                                            <div>
                                                <input v-model="player.kilometers" type="text" class="w-[28px] h-[28px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[16px] outline-none text-bold" placeholder="00">
                                            </div>
                                        </div>
                                        <div class="relative w-[14px] text-center text-[28px] text-bold text-[#BD9135]">

                                        </div>
                                        <div class="w-[28px]">
                                            <div>
                                                <input v-model="player.meters" type="text" class="w-[28px] h-[28px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[16px] outline-none text-bold" placeholder="00">
                                            </div>
                                        </div>
                                        <div class="relative w-[14px] text-center text-[28px] text-bold text-[#BD9135]">

                                        </div>
                                        <div class="w-[28px]">
                                            <div>
                                                <input v-model="player.centimeters" type="text" class="w-[28px] h-[28px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[16px] outline-none text-bold" placeholder="00">
                                            </div>
                                        </div>
                                        <div class="relative w-[14px] text-center text-[28px] text-bold text-[#BD9135]">

                                        </div>
                                        <div class="w-[28px]">
                                            <div>
                                                <input v-model="player.milimeters" type="text" class="w-[28px] h-[28px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[16px] outline-none text-bold" placeholder="00">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="element.score_board_type == 'count'" class="flex justify-center mt-[20px]">
                                        <div class="w-[65px]">
                                            <div>
                                                <input v-model="player.count" type="text" class="w-[65px] h-[28px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[16px] outline-none text-bold" placeholder="0000">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="element.score_board_type == 'point'" class="flex justify-center mt-[20px] gap-[15px]">
                                        <div @click="player.point = 1" :class="{'border-[3px]' : player.point == 1}" class="bg-[#DA4167] bg-opacity-20 border-[1px] border-[#DA4167] w-[28px] h-[28px] flex justify-center items-center font-bold rounded-full text-[#DA4167]">1</div>
                                        <div @click="player.point = 2" :class="{'border-[3px]' : player.point == 2}" class="bg-[#DA4167] bg-opacity-20 border-[1px] border-[#DA4167] w-[28px] h-[28px] flex justify-center items-center font-bold rounded-full text-[#DA4167]">2</div>
                                        <div @click="player.point = 3" :class="{'border-[3px]' : player.point == 3}" class="bg-[#BD9135] bg-opacity-20 border-[1px] border-[#BD9135] w-[28px] h-[28px] flex justify-center items-center font-bold rounded-full text-[#BD9135]">3</div>
                                        <div @click="player.point = 4" :class="{'border-[3px]' : player.point == 4}" class="bg-[#339989] bg-opacity-20 border-[1px] border-[#339989] w-[28px] h-[28px] flex justify-center items-center font-bold rounded-full text-[#339989]">4</div>
                                        <div @click="player.point = 5" :class="{'border-[3px]' : player.point == 5}" class="bg-[#339989] bg-opacity-20 border-[1px] border-[#339989] w-[28px] h-[28px] flex justify-center items-center font-bold rounded-full text-[#339989]">5</div>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div>
                                    <div v-if="player.tested && !player.retestFlag" @mouseenter="player.retestFlag = true"  class="bg-[#474849] cursor-pointer mx-1 h-[18px] pt-[2px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-white">
                                        TESTED
                                    </div>
                                    <div 
                                        v-else-if="player.tested && player.retestFlag" 
                                        @mouseleave="player.retestFlag = false" 
                                        class="bg-[#DA4167] cursor-pointer mx-1 h-[18px] pt-[2px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-white"
                                        @click="updateStudentTested({student: player, standart: element, mark: mark, value: value})"    
                                    >
                                        RETEST
                                    </div>
                                    <div v-else @click="setStudentTested({student: player, standart: element, mark: mark, value: value})" class="bg-[#0ABFC2] cursor-pointer mx-1 h-[18px] pt-[2px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-white">
                                        START
                                    </div>  
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </TableComponent>
            </TableWrapper>
            <div v-if="loaded" class="relative h-full w-full">
                <div class="w-5 h-5 rounded-full animate-spin border-solid border-4 border-[#DA4167] border-t-transparent absolute top-[50%] left-[50%] -mt-5 -ml-[10px] z-50"></div>
            </div>
        </div>
    </div>

    <div v-if="showMediaFlag" class="w-full flex flex-col items-center justify-center">
        <YouTube 
            v-if="videoToShow"
            :src="videoToShow" 
            class="w-full"
            ref="youtube" 
        />
        <div @click="() => {showMediaFlag = false; videoToShow = ''}" class="bg-[#BD9135] text-[#252526] cursor-pointer mt-[20px] border-[1px] text-center border-[#BD9135] text-[18px] w-[20%] py-[3px] px-[5px] rounded-[6px]">Назад</div>
    </div>

    <div v-if="!showMediaFlag" class="w-[100%] flex flex-col items-center justify-center mt-[50px]">
        <div @click="$emit('back')" class="bg-[#BD9135] text-[#252526] cursor-pointer mt-[20px] border-[1px] text-center border-[#BD9135] text-[18px] w-[20%] py-[3px] px-[5px] rounded-[6px]">Назад</div>
    </div>
</template>

<script>
    import { ref, onMounted, watch, computed } from "vue";
    import YouTube from 'vue3-youtube';
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
    import { getAllStudentsListInGroup, addPlayerStandard, getAllStudentStandardsByStudentId, updatePlayerStandardByReportId } from '@/services/apiRequests'
    import TableCell from "@/components/uikit/TableComponent/table/TableCell.vue";
import { maxValue } from "@vuelidate/validators";

    function roundedMilliseconds(value) {
        const newFloat = parseFloat('0.' + value);
        const returnValue = Math.round(+newFloat * 100);
        return returnValue;
    }

    export default {
        name: "ElementView",

        props: {
            element: {
                type: Object,
                default: () => {}
            },
            training: {
                type: Object,
                default: () => {}
            }
        },

        setup(props) {
            const videoToShow = ref('')
            const showMediaFlag = ref(false)

            const apiURL = process.env.VUE_APP_API_URL

            const allStudents = ref([])

            const loaded = ref(true)

            const updatedPlayers = ref([])
            const settedPlayers = ref([])

            onMounted(async () => {
                console.log('ElementView mounted', props.element)

                allStudents.value = await getAllStudentsListInGroup(props.training.group_id)
                console.log('allStudents', allStudents.value)
                await startStandardCarrying(props.element)

                loaded.value = false
            })

            const parseElementType = (type) => {
                switch (type) {
                    case 'standard':
                        return 'Норматив'
                    case 'exercise':
                        return 'Вправа'
                    case 'task':
                        return 'Завдання'
                    default:
                        return 'Невідомий тип елементу'
                }
            }

            const parseStandardType = (type) => {
                switch (type) {
                    case 'time':
                        return 'час'
                    case 'length':
                        return 'відстань'
                    case 'count':
                        return 'кількість'
                    case 'point':
                        return 'оцінка'
                    default:
                        return 'невідомий тип нормативу'
                }
            }

            const parseAgeGroup = (ageGroup) => {
                switch (ageGroup) {
                    case '6_years':
                        return '6 років'
                    case '7_years':
                        return '7 років'
                    case '8_years':
                        return '8 років'
                    case '9_years':
                        return '9 років'
                    case '10_years':
                        return '10 років'
                    case '11_years':
                        return '11 років'
                    case '12_years':
                        return '12 років'
                    case '13_years':
                        return '13 років'
                    case '14_years':
                        return '14 років'
                    case '15_years':
                        return '15 років'
                    case '16_years':
                        return '16 років'
                    case '17_years':
                        return '17 років'
                    case '18_years':
                        return '18 років'
                    case '19_years':
                        return '19 років'
                    case '20_years':
                        return '20 років'
                    case '21_years':
                        return '21 рік'
                    case '22_years':
                        return '22 роки'
                    case '23_years':
                        return '23 роки'
                    case 'main_team':
                        return 'Основний склад'
                    default:
                        return 'Невідома вікова категорія'
                }
            }

            const parseScoring = (scoring) => {
                switch (scoring) {
                    case 'time':
                        return 'Час'
                    case 'point':
                        return 'Оцінка'
                    case 'length':
                        return 'Відстань'
                    default:
                        return '-'
                }
            }

            const typeScore = ref(props.element.score_board_type);

            const minValue = ref(props.element.minimal_value);
            const middleValue = ref(props.element.middle_value);
            const maxValue = ref(props.element.maximal_value);

            const miliseconds = ref(0);
            const seconds = ref(0);
            const minutes = ref(0);
            const hours = ref(0);

            const milimeters = ref(0);
            const centimeters = ref(0);
            const meters = ref(0);
            const kilometers = ref(0);

            const point = ref(0);

            const count = ref(0);

            const formattedValue = ref('');
            const value = ref(0);

            // watch([miliseconds, seconds, minutes, hours], (newValue) => {
            //     formattedValue.value = `${hours.value}:${minutes.value}:${seconds.value},${miliseconds.value}`;
            //     value.value = +seconds.value + +minutes.value * 60 + +hours.value * 3600 + +miliseconds.value / 1000;
            // });

            watch(allStudents, (newValue) => {
                allStudents.value.forEach(student => {
                    if (typeScore.value == 'time') {
                        student.valueTime = +student.seconds + +student.minutes * 60 + +student.hours * 3600 + +student.miliseconds / 100;
                    } else if (typeScore.value == 'length') {
                        student.valueLength = +student.milimeters / 10 + +student.centimeters + +student.meters * 100 + +student.kilometers * 100;
                    } else if (typeScore.value == 'count') {
                        student.valueCount = +student.count;
                    } else if (typeScore.value == 'point') {
                        student.valuePoint = +student.point;
                    }

                    student.seconds = student.seconds ? +student.seconds : 0;
                    student.minutes = student.minutes ? +student.minutes : 0;
                    student.hours = student.hours ? +student.hours : 0;
                    student.miliseconds = student.miliseconds ? +student.miliseconds : 0;

                    student.milimeters = student.milimeters ? +student.milimeters : 0;
                    student.centimeters = student.centimeters ? +student.centimeters : 0;
                    student.meters = student.meters ? +student.meters : 0;
                    student.kilometers = student.kilometers ? +student.kilometers : 0;

                    student.point = student.point ? +student.point : 1;

                    student.count = student.count ? +student.count : 0;

                    student.mark = typeScore.value == 'time' ? getMarkByValue(student.valueTime) : typeScore.value == 'length' ? getMarkByValue(student.valueLength) : typeScore.value == 'count' ? getMarkByValue(student.valueCount) : getMarkByValue(student.valuePoint);

                    student.rating

                    const studentsWithResults = allStudents.value.filter(student => student.tested);


                    studentsWithResults.sort((a, b) => {
                        return +a.valueTime - +b.valueTime;
                    })

                    student.ratingPlace = studentsWithResults.findIndex(stud => stud.student_id == student.student_id) + 1;
                }) 
            }, {deep: true})

            const getMarkByValue = (value) => {
                if (typeScore.value == 'length') {
                    if (value < minValue.value * 100) {
                        return 'bad';
                    } else if (value >= minValue.value * 100 && value < maxValue.value * 100) {
                        return 'good';
                    } else if (value >= maxValue.value * 100) {
                        return 'excellent';
                    }
                } else if (typeScore.value == 'time') {
                    if (value < minValue.value) {
                        return 'excellent';
                    } else if (value >= minValue.value && value < maxValue.value) {
                        return 'good';
                    } else if (value >= maxValue.value) {
                        return 'bad';
                    }
                } else if (typeScore.value == 'count') {
                    if (value < minValue.value) {
                        return 'bad';
                    } else if (value >= minValue.value && value < maxValue.value) {
                        return 'good';
                    } else if (value >= maxValue.value) {
                        return 'excellent';
                    }
                } else if (typeScore.value == 'point') {
                    if (value < 3) {
                        return 'bad';
                    } else if (value >= 3 && value < 4) {
                        return 'good';
                    } else if (value >= 4) {
                        return 'excellent';
                    }
                }
            }

            //markerPosition in range -5 to 93
            const markerPosition = computed(() => {
                if (typeScore.value == 'length') {
                    if (value.value < minValue.value) {
                        //position in range 0% to 22.5%
                        return ((value.value / minValue.value) * 22.5) - 5;
                    } else if (value.value >= minValue.value && value.value < maxValue.value) {
                        //position in range 22.5% to 77.5%
                        return ((value.value - minValue.value) / (maxValue.value - minValue.value) * 51) + 17.5;
                    } else if (value.value >= maxValue.value) {
                        //position in range 77.5% to 100%
                        return Math.log(value.value) * 10 + 12 <= 92 ? Math.log(value.value) * 10 + 12 : 92;
                    }
                } else if (typeScore.value == 'time') {
                    if (value.value < minValue.value) {
                        //position in range 0% to 22.5%
                        return ((value.value / minValue.value) * 22.5) - 5;
                    } else if (value.value >= minValue.value && value.value < maxValue.value) {
                        //position in range 22.5% to 77.5%
                        return ((value.value - minValue.value) / (maxValue.value - minValue.value) * 51) + 17.5;
                    } else if (value.value >= maxValue.value) {
                        //position in range 77.5% to 100%
                        return Math.log(value.value) * 10 + 12 <= 92 ? Math.log(value.value) * 10 + 12 : 92;
                    }
                } else if (typeScore.value == 'count') {
                    if (value.value < minValue.value) {
                        //position in range 0% to 22.5%
                        return ((value.value / minValue.value) * 22.5) - 5;
                    } else if (value.value >= minValue.value && value.value < maxValue.value) {
                        //position in range 22.5% to 77.5%
                        return ((value.value - minValue.value) / (maxValue.value - minValue.value) * 51) + 17.5;
                    } else if (value.value >= maxValue.value) {
                        //position in range 77.5% to 100%
                        return Math.log(value.value) * 10 + 12 <= 92 ? Math.log(value.value) * 10 + 12 : 92;
                    }
                } 
            });

            // watch(count, (newValue) => {
            //     formattedValue.value = `${count.value}`;
            //     value.value = +count.value;
            // });

            const setStudentTested = async (ctx) => {
                const student = ctx.student
                const standart = ctx.standart

                // const studentIndex = currentStandartPlayers.value.findIndex(player => player.student_id === student.student_id)

                student.tested = true
                student.result = ctx.mark

                console.log(ctx)

                const result = typeScore.value == 'time' ? student.valueTime : typeScore.value == 'length' ? student.valueLength : typeScore.value == 'count' ? student.valueCount : student.valuePoint;

                console.log({
                    student_id: student.student_id,
                    training_id: props.training.training_id,
                    base_standard_name: standart.name,
                    report_id: new Date().getTime(),
                    base_standard_id: standart.base_standard_id,
                    result: result
                })

                await addPlayerStandard({
                    student_id: student.student_id,
                    training_id: props.training.training_id,
                    base_standard_name: standart.name,
                    report_id: new Date().getTime(),
                    base_standard_id: standart.base_standard_id,
                    result: result
                })

                settedPlayers.value.push({
                    student_name: student.name + ' ' + student.surname,
                    student_id: student.student_id,
                })

                setTimeout(() => {
                    settedPlayers.value = settedPlayers.value.filter(player => player.student_id != student.student_id)
                    console.log('settedPlayers', settedPlayers.value)
                    console.log('student.student_id', student.student_id)
                }, 3000)

                // currentStudentTestingFlag.value = false
            }

            const updateStudentTested = async (ctx) => {
                const student = ctx.student
                const standart = ctx.standart
                console.log('ctx', ctx)

                //const studentIndex = currentStandartPlayers.value.findIndex(player => player.student_id === student.student_id)

                student.tested = true
                student.result = ctx.mark

                const result = typeScore.value == 'time' ? student.valueTime : typeScore.value == 'length' ? student.valueLength : typeScore.value == 'count' ? student.valueCount : student.valuePoint;

                console.log({
                    student_id: student.student_id,
                    training_id: props.training.training_id,
                    base_standard_name: standart.name,
                    report_id: standart.report_id,
                    base_standard_id: standart.base_standard_id,
                    result: ctx.value
                })

                await updatePlayerStandardByReportId(student.savedReportId, {
                    student_id: student.student_id,
                    training_id: props.training.training_id,
                    base_standard_name: standart.name,
                    report_id: student.savedReportId,
                    base_standard_id: standart.base_standard_id,
                    result: result
                })

                updatedPlayers.value.push({
                    student_name: student.name + ' ' + student.surname,
                    student_id: student.student_id,
                })

                setTimeout(() => {
                    updatedPlayers.value = updatedPlayers.value.filter(player => player.student_id != student.student_id)
                    console.log('updatedPlayers', updatedPlayers.value)
                }, 3000)

                // updateStudentTestingFlag.value = false
            }

            const startStandardCarrying = async (standartBase) => {
                const currentStandartPlayers = []

                for (let player of allStudents.value) {
                    let standardsRes = await getAllStudentStandardsByStudentId(player.student_id)
                    console.log('standardsRes', standardsRes)

                    let studentStandart = standardsRes.find(standard => standard.training_id === props.training.training_id && standard.base_standard_id === standartBase.base_standard_id)

                    if (studentStandart) {
                        currentStandartPlayers.push({
                            ...player,
                            savedReportId: studentStandart.report_id,
                            miliseconds: `${studentStandart.result}`.split('.')[1] ? roundedMilliseconds(`${studentStandart.result}`.split('.')[1]) : 0,
                            seconds: parseFloat(`${studentStandart.result}`.split('.')[0]) % 60,
                            minutes: Math.floor(parseFloat(`${studentStandart.result}`.split('.')[0]) / 60) % 60,
                            hours: Math.floor(parseFloat(`${studentStandart.result}`.split('.')[0]) / 3600),

                            milimeters: `${+studentStandart.result}`.split('.')[1] ? `${+studentStandart.result}`.split('.')[1] : 0,
                            centimeters: parseInt(`${+studentStandart.result % 100}`.split('.')[0]),
                            meters: parseInt(`${+studentStandart.result / 100}`.split('.')[0]),
                            kilometers: (+studentStandart.result / 10000).toFixed(0),

                            point: +studentStandart.result,
                            count: +studentStandart.result,

                            tested: true,
                            result: null
                        })
                    } else {
                        currentStandartPlayers.push({
                            ...player,
                            tested: false,
                            result: null
                        })
                    }
                }

                allStudents.value = currentStandartPlayers
            }

            const sortStudentsByResult = (students) => {
                const studentsWithResults = students.filter(student => student.tested);
                const studentsWithoutResults = students.filter(student => !student.tested);

                studentsWithResults.sort((a, b) => {
                    return +a.valueTime - +b.valueTime
                })

                return studentsWithResults.concat(studentsWithoutResults)
            }

            return {
                parseElementType,
                parseAgeGroup,
                parseScoring,
                videoToShow,
                showMediaFlag,
                apiURL,
                allStudents,
                parseStandardType,
                typeScore,
                minValue,
                middleValue,
                maxValue,
                miliseconds,
                seconds,
                minutes,
                hours,
                milimeters,
                centimeters,
                meters,
                kilometers,
                point,
                count,
                formattedValue,
                value,
                markerPosition,
                setStudentTested,
                updateStudentTested,
                loaded,
                updatedPlayers,
                settedPlayers,
                sortStudentsByResult
            }
        },

        components: {
            YouTube,
            Carousel,
            Slide
        }
    }
</script>

<style lang="scss" scoped>

</style>