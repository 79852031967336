<template>
    <div class="px-[65px]">
        <div @click="$router.go(-1)" class="bg-[#BD9135] mb-[20px] flex justify-center items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
            <span>Назад</span>
        </div>
        <div>
            <TableWrapper>
                <TableComponent :fullWidth="true">
                    <TableHeader>
                    <TableHeaderRow>
                        <TableHeaderCell :textAlign="'start'" :value="'дата'" />
                        <TableHeaderCell :value="'вік'" />
                        <TableHeaderCell :value="'команда'" />
                        <TableHeaderCell :value="'тестування провів'" />
                        <TableHeaderCell :value="'протестовано'" />
                        <TableHeaderCell :value="'результат'" />
                        <TableHeaderCell :value="'найкращий результат'" />
                        <TableHeaderCell :value="'найгірший результат'" />
                        <TableHeaderCell :value="'звіт'" :textAlign="'end'" />
                    </TableHeaderRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell @click="$router.push('/testing/1/2')" class="cursor-pointer" :textAlign="'start'" :asChild="true">
                                26.10.2024
                            </TableCell>
                            <TableCell :asChild="true">
                                8 р.
                            </TableCell>
                            <TableCell :asChild="true">
                                U-2016
                            </TableCell>
                            <TableCell :asChild="true">
                                Зеленський В.В.
                            </TableCell>
                            <TableCell :asChild="true">
                                <span class="text-red-500">21</span><span>/25</span>
                            </TableCell>
                            <TableCell :asChild="true">
                                <span class="text-green-500">12</span> <span class="text-yellow-500">8</span> <span class="text-red-500">1</span>
                            </TableCell>
                            <TableCell :asChild="true">
                                <span class="font-bold text-green-500">1.20</span>
                            </TableCell>
                            <TableCell :asChild="true">
                                <span class="font-bold text-red-500">6.20</span>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div class="flex justify-end cursor-pointer">
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 15.5L5.5 11.5" stroke="#C59014" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M10.5 15.5L10.5 5.5" stroke="#C59014" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M15.5 15.5L15.5 9.5" stroke="#C59014" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M1 10.5C1 6.02166 1 3.78249 2.39124 2.39124C3.78249 1 6.02166 1 10.5 1C14.9783 1 17.2175 1 18.6088 2.39124C20 3.78249 20 6.02166 20 10.5C20 14.9783 20 17.2175 18.6088 18.6088C17.2175 20 14.9783 20 10.5 20C6.02166 20 3.78249 20 2.39124 18.6088C1 17.2175 1 14.9783 1 10.5Z" stroke="#C59014" stroke-width="1.5" stroke-linejoin="round"/>
                                    </svg>

                                </div>
                            </TableCell>
                            
                        </TableRow>
                        <TableRow>
                            <TableCell @click="$router.push('/testing/1/2')" class="cursor-pointer" :textAlign="'start'" :asChild="true">
                                24.10.2024
                            </TableCell>
                            <TableCell :asChild="true">
                                8 р.
                            </TableCell>
                            <TableCell :asChild="true">
                                U-2016
                            </TableCell>
                            <TableCell :asChild="true">
                                Зеленський В.В.
                            </TableCell>
                            <TableCell :asChild="true">
                                <span class="text-green-500">25</span><span>/25</span>
                            </TableCell>
                            <TableCell :asChild="true">
                                <span class="text-green-500">12</span> <span class="text-yellow-500">8</span> <span class="text-red-500">1</span>
                            </TableCell>
                            <TableCell :asChild="true">
                                <span class="font-bold text-green-500">1.20</span>
                            </TableCell>
                            <TableCell :asChild="true">
                                <span class="font-bold text-red-500">6.20</span>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div class="flex justify-end cursor-pointer">
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 15.5L5.5 11.5" stroke="#C59014" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M10.5 15.5L10.5 5.5" stroke="#C59014" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M15.5 15.5L15.5 9.5" stroke="#C59014" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M1 10.5C1 6.02166 1 3.78249 2.39124 2.39124C3.78249 1 6.02166 1 10.5 1C14.9783 1 17.2175 1 18.6088 2.39124C20 3.78249 20 6.02166 20 10.5C20 14.9783 20 17.2175 18.6088 18.6088C17.2175 20 14.9783 20 10.5 20C6.02166 20 3.78249 20 2.39124 18.6088C1 17.2175 1 14.9783 1 10.5Z" stroke="#C59014" stroke-width="1.5" stroke-linejoin="round"/>
                                    </svg>

                                </div>
                            </TableCell>
                            
                        </TableRow>
                    </TableBody>
                </TableComponent>
            </TableWrapper>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TestingView',
}
</script>
<style lang="">
    
</style>