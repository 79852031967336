<template>
    <div>
        <div class="text-center text-white font-semibold">
            Виберіть групу тестувань
        </div>
        <div>
            <div @click="$router.push('/testing/1')" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                <span><span class="text-black">Фізичні тестування</span></span>
            </div>
            <div class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                <span><span class="text-black">Психо-фізіологічні тестування</span></span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TestingView',
}
</script>
<style lang="">
    
</style>